export const AnalyticsHomeVariantConstants = {
  homepageList: 'homepageList',
  pageSection: {
    payments: 'Payments',
    refunds: 'Refunds',
    reverse: 'Reverse',
    transactions: 'Transactions',
    transfers: 'Transfers',
    totalTakings: 'Total takings',
    billing: 'Billing',
    businessFunding: 'Business funding',
    insights: 'Insights',
    viewCardMachines: 'View card machines',
    cardMachineOrders: 'Card machine orders',
    orderCardMachines: 'Order card machines',
    onlineCheckout: 'Online checkout',
    developerPortal: 'Developer portal',
    viewTeamMembers: 'View team members',
    addTeamMembers: 'Add team members',
    virtualQueuesAndBookings: 'Manage queues and bookings',
    paymentLink: 'Payment link',
    virtualTerminal: 'Virtual terminal',
    payByQR: 'Pay by Qr',
    payByBank: 'Pay by Bank',
    visitHelpCentre: 'Visit Help Centre',
    dojoCard: 'Dojo Card',
  },
  actionSuffixes: {
    totalTakings: 'TotalTakings',
    paymentLinks: 'PaymentLinks',
    virtualTerminal: 'VirtualTerminal',
    payByBank: 'payByBank',
    payments: 'Payments',
    refunds: 'Refunds',
    reverse: 'Reverse',
    transactions: 'Transactions',
    transfers: 'Transfers',
    billing: 'Billing',
    businessFunding: 'BusinessFunding',
    insights: 'Insights',
    insightsV3: 'InsightsV3',
    viewCardMachines: 'ViewCardMachines',
    cardMachineOrders: 'CardMachineOrders',
    orderCardMachines: 'OrderCardMachines',
    onlineCheckout: 'OnlineCheckout',
    developerPortal: 'DeveloperPortal',
    viewTeamMembers: 'ViewTeamMembers',
    addTeamMembers: 'AddTeamMembers',
    virtualQueuesAndBookings: 'VirtualQueuesAndBookings',
    PayByQr: 'PayByQr',
    visitHelpCentre: 'VisitHelpCentre',
    dojoCard: 'DojoCard',
    insightsV3Details: 'insightsV3Details',
  },
  actions: {
    seen: 'seen',
    selected: 'selected',
  },
};
