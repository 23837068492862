import useShowCardMachines from '@/hooks/useShowCardMachines';
import { Permissions } from '@/utils/permissions';
import { useAnalytics, useBraze, useCustomers } from 'framework';
import { LoadingPage } from 'legacy-components';
import { AnalyticsCategory } from 'legacy-features';
import { i18n } from 'legacy-i18n';
import { DjpLocationSwitcher, DjpSetTitle } from 'legacy-stencil-react';
import { useEffect, useState } from 'react';
import { AnalyticsHomeVariantConstants } from '../../constants/analytics/home-variant';
import Authorization from '../navigation/authorization/authorization';
import {
  centeringContainerStyle,
  contentContainerStyle,
  pageContainerStyle,
  sectionDividerStyle,
  sectionGroupStyle,
  topSectionContainerStyle,
  topSectionStyle,
} from './home.css';
import BottomSections from './sections/bottomSections';
import TakePaymentSection from './sections/takePaymentSection';
import TotalTakings from './totalTakings/totalTakings';

export default function Home() {
  const { analytics } = useAnalytics();
  const { customer, loading: loadingCustomers } = useCustomers();
  const { customerId, locations } = customer ?? {};
  const { logCustomEvent } = useBraze();

  const [userSelectedLocationId, setUserSelectedLocationId] = useState<string | null>(null);
  const locationId = userSelectedLocationId || (locations?.length > 1 ? '0' : locations?.[0]?.locationId || null);
  const { shouldShowCardMachines } = useShowCardMachines(locationId);

  useEffect(() => {
    if (locationId) {
      analytics.page(
        AnalyticsCategory.homepage,
        { name: AnalyticsHomeVariantConstants.homepageList },
        { locationId: locationId === '0' ? 'allLocations' : locationId }
      );
    }
  }, [analytics, locationId]);

  useEffect(() => {
    logCustomEvent('page_homepage_homepageList');
  }, [logCustomEvent]);

  if (loadingCustomers) {
    return <LoadingPage />;
  }

  const selectedLocations = locationId === '0' ? [...locations] : locations.filter((location) => location.locationId === locationId);

  const shouldShowPaymentLinks = selectedLocations?.some((l) => l.remotePaymentAppConfig?.paymentLinks?.enabled);
  const shouldShowVirtualTerminal = selectedLocations?.some((l) => l.remotePaymentAppConfig?.virtualTerminal?.enabled);
  const shouldShowOnlinePayments = selectedLocations?.some((l) => l.remotePaymentAppConfig?.portal?.enabled);
  const shouldShowPayByBank = selectedLocations?.some((l) => l.remotePaymentAppConfig?.payByBank?.enabled);

  const selectLocation = (locationId: string) =>
    new Promise<void>(async (resolve, reject) => {
      try {
        setUserSelectedLocationId(locationId);
        return resolve();
      } catch (_) {
        // handle error
      }
      reject();
    });

  const locationIds = selectedLocations.map((l) => l.locationId);

  return (
    <div className={pageContainerStyle}>
      <DjpSetTitle pageTitle={i18n.t('home:home.overview.page_title')} />

      <div className={topSectionContainerStyle}>
        <div className={contentContainerStyle}>
          {locations && locations.length > 0 && (
            <div style={{ marginBottom: '16px' }}>
              <DjpLocationSwitcher
                locations={locations}
                selectLocation={selectLocation}
                selectedLocationId={locationId}
                i18n={i18n}
                analyticsName={AnalyticsHomeVariantConstants.homepageList}
              />
            </div>
          )}
          <div className={topSectionStyle}>
            <Authorization permission={Permissions.transactionsAllView}>
              <TotalTakings customerId={customerId} locationIds={locationIds} />
            </Authorization>
            <TakePaymentSection
              shouldShowPaymentLinks={shouldShowPaymentLinks}
              shouldShowVirtualTerminal={shouldShowVirtualTerminal}
              showPayByBank={shouldShowPayByBank}
              userSelectedLocationId={userSelectedLocationId}
            />
          </div>
        </div>
      </div>

      <hr className={sectionDividerStyle} />

      <div className={centeringContainerStyle}>
        <div className={contentContainerStyle}>
          <div className={sectionGroupStyle}>
            <BottomSections shouldShowOnlinePayments={shouldShowOnlinePayments} shouldShowCardMachines={shouldShowCardMachines} />
          </div>
        </div>
      </div>
    </div>
  );
}
